<template>
  <app-layout>
    <div class="flex flex-col p-4 items-center pt-16">
      <div class="w-full px-12">
        <div class="flex flex-col space-y-6 text-center">
          <el-tabs type="border-card" v-model="activeTab">
            <el-tab-pane name="finances" label="Finanzas">
              <Finances :activeTab="activeTab" />
            </el-tab-pane>
            <el-tab-pane name="statistics" label="Estadisticas">
              <Statistics :activeTab="activeTab" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import Finances from './Finances';
import Statistics from './Statistics';

export default {
  name: "FinancesIndex",
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout'),
    Finances,
    Statistics,
  },
  data() {
    return {
      activeTab: 'finances',
    }
  },
};
</script>
