import HttpProxy from '@/services/HttpProxy';

export default class StatisticService {
  static async get(data) {
    const response = await HttpProxy.submit({ method: 'post', url: '/Statistics', data });
    return response?.data ?? null;
  }

  static async getTop10Customers(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: '/Statistics/Top10Customers', parameters });
    return response?.data ?? null;
  }

  static async download(data) {
    const response = await HttpProxy
      .submit({ method: 'post', url: `/Statistics/DownloadFile`, data });
    return response?.data ?? null;
  }
}
