<template>
  <div>
    <LoadingScreen :isLoading="loading"></LoadingScreen>
    <div>
      <date
        :visible="showSelectDate"
        :isRange="isRangeDate"
        @close="showSelectDate = false"
        @on:update="updateFilters(DateFilterTypes.Day, $event)"
      >
      </date>

      <div class="relative mb-2">
        <div class="w-full flex flex-row justify-center">
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer relative day-common-size"
            :class="{
              'bg-purple-500 text-white day-maxed-size':
                filters.filterType === DateFilterTypes.Day,
            }"
            @click="showSelectDate = true"
          >
            <i class="bx bx-calendar"></i>&nbsp;Fecha
            <span
              class="absolute text-sm filter-day-date"
              v-if="filters.filterType === DateFilterTypes.Day"
            >
              {{ filterByDayDate }}
            </span>
            <div class="tooltip" @click.stop="clearDate">
              <button class="delete-btn">
                <i class="bx bx-x"></i>
              </button>
              <span class="tooltiptext">
                <i class="bx bx-trash"></i>
              </span>
            </div>
          </div>
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{
              'bg-purple-500 text-white':
                filters.filterType === DateFilterTypes.Week,
            }"
            style="min-width: 100px; width: 100px"
            @click="updateFilters(DateFilterTypes.Week)"
          >
            Semana
          </div>
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{
              'bg-purple-500 text-white':
                filters.filterType === DateFilterTypes.Month,
            }"
            style="min-width: 100px; width: 100px"
            @click="updateFilters(DateFilterTypes.Month)"
          >
            Mes
          </div>
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{
              'bg-purple-500 text-white':
                filters.filterType === DateFilterTypes.Year,
            }"
            style="min-width: 100px; width: 100px"
            @click="updateFilters(DateFilterTypes.Year)"
          >
            Año
          </div>
        </div>

        <el-select
          v-model="entityType"
          @change="load(filters)"
          class="absolute top-0 right-0"
        >
          <el-option
            :value="FinancesFilterType.Order"
            :label="FinancesFilterTypeDict[FinancesFilterType.Order]"
          ></el-option>
          <el-option
            :value="FinancesFilterType.Expense"
            class="flex items-center"
            :disabled="!isProMembership"
            :label="FinancesFilterTypeDict[FinancesFilterType.Expense]"
          >
            Gastos
            <pro-link v-if="!isProMembership"></pro-link
          ></el-option>
          <el-option
            :value="FinancesFilterType.Utility"
            class="flex items-center"
            :disabled="!isProMembership"
            :label="FinancesFilterTypeDict[FinancesFilterType.Utility]"
          >
            Utilidad
            <pro-link v-if="!isProMembership"></pro-link
          ></el-option>
          <el-option
            :value="FinancesFilterType.Product"
            class="flex items-center"
            :disabled="!isProMembership"
            :label="FinancesFilterTypeDict[FinancesFilterType.Product]"
          >
            Inventario
            <pro-link v-if="!isProMembership"></pro-link
          ></el-option>
        </el-select>
      </div>
      <div v-if="showChart">
        <div class="w-full flex flex-row justify-end">
          <chart-dashboard
            v-for="dataset of chartOptions.datasets"
            :key="dataset.label"
            :label="dataset.label"
            :amount="dataset.data.reduce((acc, curr) => acc + curr, 0)"
            :background="dataset.borderColor"
            :customProps="dataset.customProps"
            class="chart-dashboard-item"
          />
        </div>
        <multi-axis-bar-chart
          :labels="chartOptions.labels"
          :datasets="chartOptions.datasets"
        ></multi-axis-bar-chart>
      </div>
    </div>
    <div v-if="entityType === FinancesFilterType.Order">
      <content-card class="w-full mt-8" :itemsCentered="false" title="Ordenes">
        <div slot="actions">
          <el-button @click="download" :disabled="data.length === 0">
            <span>Exportar</span>
          </el-button>
        </div>
        <orders-grid
          :orders="data"
          :hasActions="false"
          referenceCanRedirectOrder
        />
      </content-card>
    </div>
    <div v-if="entityType === FinancesFilterType.Expense">
      <content-card class="w-full mb-4" :itemsCentered="false" title="Gastos">
        <div slot="actions">
          <el-button @click="download" :disabled="data.length === 0">
            <span>Exportar</span>
          </el-button>
        </div>
        <div class="block w-full overflow-x-auto">
          <table class="items-center w-full border-collapse">
            <thead>
              <tr class="px-2 border-b-2">
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Tipo
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Categoria
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Subcategoria
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Monto
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Fecha Gasto
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Fecha Creado
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Observación
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="expense in data" :key="expense.id" class="text-left">
                <td class="p-2 border-t w-24">
                  {{ ExpenseTypesDict[expense.expenseType] }}
                </td>
                <td class="p-2 border-t w-28">
                  {{ ExpenseCategoryDict[expense.expenseCategory] }}
                </td>
                <td class="p-2 border-t w-48">
                  {{ getExpenseSubcategory(expense) }}
                </td>
                <td class="p-2 border-t w-28">
                  {{ moneyFormat(expense.amount) }}
                </td>
                <td class="p-2 border-t w-40">
                  <el-date-picker
                    v-model="expense.expenseDate"
                    type="date"
                    disabled
                    :format="$store.state.business.dateFormat"
                    class="w-full bg-select-button"
                  >
                  </el-date-picker>
                </td>
                <td class="p-2 border-t w-40">
                  <el-date-picker
                    v-model="expense.createdAt"
                    type="date"
                    disabled
                    :format="$store.state.business.dateFormat"
                    class="w-full bg-select-button"
                  >
                  </el-date-picker>
                </td>
                <td class="p-2 border-t">
                  <el-popover placement="left" width="250" trigger="click">
                    <div>
                      <div class="flex flex-col">
                        <span class="font-bold">Observaciones</span>
                        <span>{{ expense.observations }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="font-bold">Referencia</span>
                        <span>{{ expense.reference }}</span>
                      </div>
                    </div>
                    <el-button slot="reference" type="text" class="text-left">
                      <span>Ver</span>
                      <i class="bx bx-chevron-down"></i>
                    </el-button>
                  </el-popover>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </content-card>
    </div>
    <div v-if="entityType === FinancesFilterType.Product">
      <content-card
        class="w-full m-4"
        :itemsCentered="false"
        title="Inventario"
      >
        <div slot="actions">
          <el-button @click="download" :disabled="data.length === 0">
            <span>Exportar</span>
          </el-button>
        </div>
        <div class="block w-full overflow-x-auto">
          <table class="items-center w-full border-collapse">
            <thead>
              <tr class="px-2 border-b-2">
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Producto
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Categoria
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Costo
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Precio
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Existencias
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product in data"
                :key="product.id"
                class="text-left hover:bg-gray-100 hover:shadow"
              >
                <td class="p-2 border-t">
                  <a
                    class="text-gray-500 hover:text-purple-500"
                    @click="
                      $router.push({
                        name: 'products.read',
                        params: { id: product.id },
                      })
                    "
                  >
                    {{ product.name }}
                  </a>
                </td>
                <td class="p-2 border-t">
                  {{ getProductCategory(product) }}
                </td>
                <td class="p-2 border-t">
                  {{ moneyFormat(product.cost) }}
                </td>
                <td class="p-2 border-t">
                  {{ moneyFormat(product.price) }}
                </td>
                <td class="p-2 border-t">
                  <span>
                    {{ product.quantity }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </content-card>
    </div>
  </div>
</template>
<style>
.highcharts-axis-labels > text {
  text-transform: capitalize;
}

.day-common-size {
  width: 100px;
  min-width: 100px;
}

.day-maxed-size {
  width: 100px;
  min-width: 125px;
  text-align: left;
  padding: 0 8px;
}

.filter-day-date {
  top: -2px;
  font-size: 8px;
  width: 49px;
  right: 0;
}

.tooltip {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
}

.delete-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  visibility: hidden;
  padding: 4px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #8b5cf6;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  left: 8px;
  /* Adjust as necessary */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.day-maxed-size:hover .delete-btn {
  visibility: visible;
}

.day-maxed-size:hover .tooltip .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.chart-dashboard-item {
  margin: 0.5rem;
  /* equivalente a m-2 */
}

.chart-dashboard-item:last-child {
  margin: 0.5rem;
  /* Elimina el margen para el último hijo */
  margin-right: 0;
}
</style>
<script>
import _ from "lodash";
import dates from "@/utils/dates";
import numbers from "@/utils/numbers";
import error from "@/mixins/error";
import filter from "@/mixins/filter";
import multiAxis from "@/mixins/multi-axis";
import {
  FinancesFilterType,
  FinancesFilterTypeDict,
  DateFilterTypes,
  DateFilterCategories,
  Translations,
  ExpenseType,
  ExpenseTypesDict,
  ExpenseCategoryDict,
} from "@/constants";
import FinanceService from "@/services/FinanceService";
import FileService from "@/services/FileService";
import TheHeaderAppServices from "@/components/organisms/services/TheHeaderAppServices";

export default {
  name: "Finances",
  mixins: [error, filter, multiAxis],
  props: {
    activeTab: {
      type: String,
      default: () => null,
    },
  },
  components: {
    ProLink: () => import("@/components/atoms/SaProLink.vue"),
    Date: () => import("@/components/dialogs/Date"),
    ContentCard: () => import("@/components/molecules/ContentCard"),
    OrdersGrid: () => import("@/views/app/orders/grid/Index"),
    ChartDashboard: () => import("@/components/charts/ChartDashboard"),

    LoadingScreen: () => import("@/components/layouts/LoadingScreen"),
  },
  created() {
    this.headerAppSubscription = TheHeaderAppServices.command.subscribe(
      this.handleHeaderAppCommands
    );
  },
  destroyed() {
    this.headerAppSubscription.unsubscribe();
  },
  mounted() {
    this.load(this.filters);
  },
  data() {
    return {
      isProMembership:
        this.$store.state.account.membershipType === 2 ||
        this.$store.state.account.membershipType === 3,
      loading: true,
      showSelectDate: false,
      entityType: FinancesFilterType.Order,
      filters: {
        filterType: DateFilterTypes.Week,
        startDate: dates.getFirstDayOfWeek(),
        endDate: dates.getLastDayOfWeek(),
      },
      data: [],
      ExpenseType,
      ExpenseTypesDict,
      ExpenseCategoryDict,
      DateFilterTypes,
      FinancesFilterType,
      FinancesFilterTypeDict,
      headerAppSubscription: null,
      isRangeDate: true,
      showChart: false,
      chartOptions: {
        labels: [],
        datasets: [],
      },
    };
  },
  computed: {
    filterByDayDate() {
      if (this.isRangeDate) {
        return `${this.dateFormat(this.filters.startDate).substr(
          0,
          5
        )} - ${this.dateFormat(this.filters.endDate).substr(0, 5)}`;
      }
      return `${this.dateFormat(this.filters.startDate).substr(0, 5)}`;
    },
  },
  methods: {
    clearDate() {
      this.showChart = false;
      this.entityType = null;
      this.filters = {};
    },
    getProductCategory(product) {
      const { productCategoryId } = product;

      const { categories } = this.$store.state.catalogs.product;

      const category = categories.find((cat) => cat.id === productCategoryId);

      if (!_.isNil(category)) {
        return category.name;
      }

      return "";
    },
    getExpenseSubcategory(expense) {
      const { expenseSubcategory } = expense;

      if (_.isNil(expenseSubcategory)) {
        return "N/A";
      }

      const staticSubcategory = this.$store.state.expenses.subcategories.find(
        (sub) => sub.id === expenseSubcategory
      );
      if (!_.isNil(staticSubcategory)) {
        return _.get(staticSubcategory, "name", "N/A");
      }

      // If is not an static subcategory, we can assume its a dynamic.
      // Due we don't get any product expense this time
      return expenseSubcategory;
    },
    dateFormat(date) {
      const format =
        this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
      return this.$moment(date).format(format);
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    updateFilters(filterType, date) {
      if (date) {
        this.filters = {
          filterType: this.filters.filterType,
          startDate: this.isRangeDate ? date[0].toString() : date,
          endDate: this.isRangeDate ? date[1].toString() : date,
        };
      }

      this.getDateFilters(this.filters, filterType, (filters) => {
        this.filters = filters;
      });

      this.load(this.filters);
    },
    getTranslatedCategories(categories, filterType) {
      return categories.map((category) => {
        const translation = Translations[filterType];
        return translation[category] || category;
      });
    },
    getFormattedOrders(orders) {
      return orders.map((order) => {
        if (!order.finalizedStatusAt) return { ...order, finalizedStatusAt: null };

        const formattedFinalizedStatusAt = `${order.finalizedStatusAt.toLocaleString()}Z`;

        return {
          ...order,
          finalizedStatusAt: formattedFinalizedStatusAt
        };
      });
    },
    buildOrderData({ orders: dictionary, advances: advancesDict } = { orders: [] }, filters) {
      try {
        const categories = DateFilterCategories[filters.filterType];
        const series = [
          { name: "Servicios", data: [], isMoney: true, scalePosition: "left" },
          { name: "Productos", data: [], isMoney: true, scalePosition: "left" },
          {
            name: "Venta Total",
            data: [],
            isMoney: true,
            scalePosition: "right",
          },
          {
            name: "Venta Neta",
            data: [],
            isMoney: true,
            scalePosition: "right",
          },
        ];

        for (const category of categories) {
          let services = 0;
          let products = 0;
          let costs = 0;
          let taxes = 0;
          let totalAmount = 0;

          const orders = categories.reduce((acc, curr) => {
            dictionary.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          const advances = categories.reduce((acc, curr) => {
            advancesDict.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (orders) {
            const formattedOrders = this.getFormattedOrders(orders);
            this.data.push(...formattedOrders);

            for (const order of orders) {
              totalAmount += order.totalAmount;

              const { orderDevices, orderProducts } = order;

              // Sumar los productos
              for (const orderProduct of orderProducts) {
                products += orderProduct.price * orderProduct.quantity;
                costs += orderProduct.cost * orderProduct.quantity;
              }

              for (const orderDevice of orderDevices) {
                const { orderDeviceServices, orderDeviceProducts } =
                  orderDevice;

                if (orderDeviceServices.length > 0) {
                  // Sumar los impuestos
                  taxes += order.taxAmount;
                }

                // Sumar los servicios
                for (const orderDeviceService of orderDeviceServices) {
                  services += orderDeviceService.price;
                  costs += orderDeviceService.cost;
                }

                // Sumar los productos por dispositivo
                for (const orderDeviceProduct of orderDeviceProducts) {
                  products +=
                    orderDeviceProduct.price * orderDeviceProduct.quantity;
                  costs +=
                    orderDeviceProduct.cost * orderDeviceProduct.quantity;
                }
              }
            }
          }

          if (advances) {
            const formattedOrders = this.getFormattedOrders(advances.map((advance => advance.order)));
            this.data.push(...formattedOrders);

            for (const advance of advances) {
              totalAmount += advance.advanceAmount;
            }
          }

          // Añadir los servicios y productos a la serie
          series[0].data.push(parseFloat((taxes + services).toFixed(2)));
          series[1].data.push(parseFloat(products.toFixed(2)));
          series[2].data.push(parseFloat(totalAmount.toFixed(2)));
          series[3].data.push(parseFloat((totalAmount - costs).toFixed(2)));
        }

        this.chartOptions.labels = this.getTranslatedCategories(
          categories,
          filters.filterType
        );
        this.chartOptions.datasets = this.getSingleAxisDatasets(series, true);
      } catch (error) {
        console.log(error);
      }
    },
    buildExpenseData({ expenses: dictionary } = { expenses: [] }, filters) {
      try {
        const categories = DateFilterCategories[filters.filterType];
        const series = [
          { name: "Fijo", data: [], isMoney: true, scalePosition: "left" },
          { name: "Variable", data: [], isMoney: true, scalePosition: "left" },
          { name: "Total", data: [], isMoney: true, scalePosition: "right" },
        ];

        for (const category of categories) {
          let statics = 0;
          let dynamics = 0;

          const expenses = categories.reduce((acc, curr) => {
            dictionary.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (expenses) {
            this.data.push(...expenses);

            for (const expense of expenses) {
              if (expense.expenseType === ExpenseType.Static) {
                // Sumar los gastos fijos
                statics += expense.amount;
              } else if (
                expense.expenseType === ExpenseType.Dynamic ||
                expense.expenseType === ExpenseType.Product
              ) {
                // Sumar los gastos variables
                dynamics += expense.amount;
              }
            }
          }

          // Añadir los gastos a la serie
          series[0].data.push(parseFloat(statics.toFixed(2)));
          series[1].data.push(parseFloat(dynamics.toFixed(2)));
          series[2].data.push(parseFloat((statics + dynamics).toFixed(2)));
        }

        this.chartOptions.labels = this.getTranslatedCategories(
          categories,
          filters.filterType
        );
        this.chartOptions.datasets = this.getSingleAxisDatasets(series, true);
      } catch (error) {
        console.log(error);
      }
    },
    buildUtilityData(filters, { orders: ordersDict, expenses: expensesDict }) {
      try {
        const categories = DateFilterCategories[filters.filterType];
        const series = [
          { name: "Ventas", data: [], isMoney: true, scalePosition: "left" },
          { name: "Gastos", data: [], isMoney: true, scalePosition: "left" },
          { name: "Total", data: [], isMoney: true, scalePosition: "right" },
        ];

        for (const category of categories) {
          let sales = 0;
          let costs = 0;

          // Sumar las ventas
          const orders = categories.reduce((acc, curr) => {
            ordersDict.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (orders) {
            for (const order of orders) {
              sales += order.totalAmount;
            }
          }

          // Sumar los gastos
          const expenses = categories.reduce((acc, curr) => {
            expensesDict.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (expenses) {
            for (const expense of expenses.filter(
              ({ expenseType }) => expenseType !== ExpenseType.Product
            )) {
              costs += expense.amount;
            }
          }

          series[0].data.push(parseFloat(sales.toFixed(2)));
          series[1].data.push(parseFloat(costs.toFixed(2)));
          series[2].data.push(parseFloat((sales - costs).toFixed(2)));
        }

        this.chartOptions.labels = this.getTranslatedCategories(
          categories,
          filters.filterType
        );
        this.chartOptions.datasets = this.getSingleAxisDatasets(series, true);
      } catch (error) {
        console.log(error);
      }
    },
    buildProductData({ products: dictionary } = { products: [] }, filters) {
      try {
        const categories = DateFilterCategories[filters.filterType];
        const series = [
          { name: "Costos", data: [], isMoney: true, scalePosition: "left" },
        ];

        for (const category of categories) {
          let costs = 0;

          const products = categories.reduce((acc, curr) => {
            dictionary.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (products) {
            this.data.push(...products);

            for (const product of products) {
              costs += product.cost * product.quantity;
            }
          }

          // Añadir los productos a la serie
          series[0].data.push(parseFloat(costs.toFixed(2)));
        }

        this.chartOptions.labels = this.getTranslatedCategories(
          categories,
          filters.filterType
        );
        this.chartOptions.datasets = this.getSingleAxisDatasets(series, true);
      } catch (error) {
        console.log(error);
      }
    },
    mapper(filters, dictionary = {}) {
      const mappers = {
        [FinancesFilterType.Order]: this.buildOrderData,
        [FinancesFilterType.Expense]: this.buildExpenseData,
        [FinancesFilterType.Product]: this.buildProductData,
      };

      const builder = mappers[this.entityType];
      return builder(dictionary, filters);
    },
    async load(filters) {
      this.data = [];
      this.loading = true;
      this.showChart = false;
      try {
        if (!this.entityType) {
          this.entityType = FinancesFilterType.Order;
        }
        if (this.entityType === FinancesFilterType.Utility) {
          const { orders } = await FinanceService.get({
            entityType: FinancesFilterType.Order,
            dateFilters: filters,
            businessInfoId: this.$store.state.business.id,
          });
          const { expenses } = await FinanceService.get({
            entityType: FinancesFilterType.Expense,
            dateFilters: filters,
            businessInfoId: this.$store.state.business.id,
          });

          this.buildUtilityData(filters, { orders, expenses });
        } else {
          this.mapper(
            filters,
            await FinanceService.get({
              entityType: this.entityType,
              dateFilters: filters,
              businessInfoId: this.$store.state.business.id,
            })
          );
        }
        this.showChart = true;
      } catch (error) {
        this.$toastr.e(this.getErrorMessage(error));
      } finally {
        this.loading = false;
      }
    },
    handleHeaderAppCommands({ type }) {
      switch (type) {
        case TheHeaderAppServices.commands.onBusinessChange:
          if (this.activeTab === "finances") {
            this.load(this.filters);
          }
          break;
        default:
      }
    },
    async download() {
      this.loading = true;
      try {
        const url = await FinanceService.download(
          this.$store.state.business.id,
          { ...this.filters, entityType: this.entityType }
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Finanzas+csv.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => this.removeFileFromS3(url), 10000);
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      } finally {
        this.loading = false;
      }
    },
    async removeFileFromS3(url) {
      const fileName = `import-export/${url.split("import-export/")[1]}`;
      await FileService.delete({ files: [fileName] });
    },
  },
};
</script>
