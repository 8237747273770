<template>
  <div>
    <div>
      <date
        :visible="showSelectDate"
        :isRange="isRangeDate"
        @close="showSelectDate = false"
        @on:update="updateFilters(DateFilterTypes.Day, $event)"
      >
      </date>
      <el-drawer
        title="Selecciona tus filtros de busqueda"
        size="50%"
        direction="rtl"
        :visible.sync="drawer"
        :before-close="handleClose"
      >
        <filters :loading="loading" @on:fetch="fetch($event)" />
      </el-drawer>
      <div class="relative mb-2">
        <div class="w-full flex flex-row justify-center">
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer relative day-common-size"
            :class="{
              'bg-purple-500 text-white day-maxed-size':
                filters.filterType === DateFilterTypes.Day,
            }"
            @click="handleShowFilters"
          >
            <i class="bx bx-calendar"></i>&nbsp;Fecha
            <span
              class="absolute text-sm filter-day-date"
              v-if="filters.filterType === DateFilterTypes.Day"
            >
              {{ filterByDayDate }}
            </span>
            <div class="tooltip" @click.stop="clearDate">
              <button class="delete-btn">
                <i class="bx bx-x"></i>
              </button>
              <span class="tooltiptext">
                <i class="bx bx-trash"></i>
              </span>
            </div>
          </div>
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{
              'bg-purple-500 text-white':
                filters.filterType === DateFilterTypes.Week,
            }"
            style="min-width: 100px; width: 100px"
            @click="updateFilters(DateFilterTypes.Week)"
          >
            Semana
          </div>
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{
              'bg-purple-500 text-white':
                filters.filterType === DateFilterTypes.Month,
            }"
            style="min-width: 100px; width: 100px"
            @click="updateFilters(DateFilterTypes.Month)"
          >
            Mes
          </div>
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{
              'bg-purple-500 text-white':
                filters.filterType === DateFilterTypes.Year,
            }"
            style="min-width: 100px; width: 100px"
            @click="updateFilters(DateFilterTypes.Year)"
          >
            Año
          </div>
        </div>

        <el-button
          class="absolute top-0 right-0"
          @click="drawer = true"
          type="primary"
        >
          <i class="bx bx-filter"></i>&nbsp;Filtrar
        </el-button>
      </div>
    </div>
    <OnlyProInfo
      v-if="!isProMembership"
      text="El panel de estadísticas no
está incluido en la membresía que tienes
actualmente, Si estás interesado en las estadísticas
puedes ir a"
    ></OnlyProInfo>
    <div v-if="showChart">
      <div class="w-full flex flex-row justify-end">
        <chart-dashboard
          v-for="dataset of chartOptions.datasets"
          :key="dataset.label"
          :label="dataset.label"
          :amount="dataset.data.reduce((acc, curr) => acc + curr, 0)"
          :background="dataset.borderColor"
          :customProps="dataset.customProps"
          class="chart-dashboard-item"
        />
      </div>
      <multi-axis-line-chart
        :labels="chartOptions.labels"
        :datasets="chartOptions.datasets"
      ></multi-axis-line-chart>
    </div>
    <div v-if="showOrdersGrid">
      <content-card class="w-full mt-8" :itemsCentered="false" title="Orders">
        <div slot="actions">
          <el-button @click="download" :disabled="gridOptions.length === 0">
            <span>Exportar</span>
          </el-button>
        </div>
        <orders-grid
          :orders="gridOptions"
          :hasActions="false"
          referenceCanRedirectOrder
        ></orders-grid>
      </content-card>
    </div>
    <div v-if="showProductsGrid">
      <content-card
        class="w-full mt-8"
        :itemsCentered="false"
        title="Productos"
      >
        <div slot="actions">
          <el-button @click="download" :disabled="gridOptions.length === 0">
            <span>Exportar</span>
          </el-button>
        </div>
        <div class="block w-full overflow-x-auto">
          <table class="items-center w-full border-collapse">
            <thead>
              <tr class="px-2 border-b-2">
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Producto
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Categoria
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Costo
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Precio
                </th>
                <th
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Existencias
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product in gridOptions"
                :key="product.id"
                class="text-left hover:bg-gray-100 hover:shadow"
              >
                <td class="p-2 border-t">
                  {{ product.name }}
                </td>
                <td class="p-2 border-t">
                  {{ product.productCategory.name }}
                </td>
                <td class="p-2 border-t">
                  {{ moneyFormat(product.cost) }}
                </td>
                <td class="p-2 border-t">
                  {{ moneyFormat(product.price) }}
                </td>
                <td class="p-2 border-t" style="width: 240px">
                  {{ product.quantity }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </content-card>
    </div>

    <LoadingScreen :isLoading="loading"></LoadingScreen>
  </div>
</template>
<style scoped>
.day-common-size {
  width: 100px;
  min-width: 100px;
}

.day-maxed-size {
  width: 100px;
  min-width: 125px;
  text-align: left;
  padding: 0 8px;
}

.filter-day-date {
  top: -2px;
  font-size: 8px;
  width: 49px;
  right: 0;
}

.tooltip {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
}

.delete-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  visibility: hidden;
  padding: 4px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #8b5cf6;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  left: 8px;
  /* Adjust as necessary */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.day-maxed-size:hover .delete-btn {
  visibility: visible;
}

.day-maxed-size:hover .tooltip .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.chart-dashboard-item {
  margin: 0.5rem;
  /* equivalente a m-2 */
}

.chart-dashboard-item:last-child {
  margin: 0.5rem;
  /* Elimina el margen para el último hijo */
  margin-right: 0;
}
</style>
<script>
import {
  DateFilterTypes,
  StatisticsFilterType,
  DateFilterCategories,
  Translations,
} from "@/constants";
import filter from "@/mixins/filter";
import error from "@/mixins/error";
import multiAxis from "@/mixins/multi-axis";
import dates from "@/utils/dates";
import StatisticService from "@/services/StatisticService";
import FileService from "@/services/FileService";
import numbers from "@/utils/numbers";
import TheHeaderAppServices from "@/components/organisms/services/TheHeaderAppServices";

export default {
  name: "Statistics",
  mixins: [error, filter, multiAxis],
  props: {
    activeTab: {
      type: String,
      default: () => null,
    },
  },
  components: {
    LoadingScreen: () => import("@/components/layouts/LoadingScreen"),
    OnlyProInfo: () => import("@/components/atoms/SaOnlyProInfo.vue"),
    Date: () => import("@/components/dialogs/Date"),
    Filters: () => import("./StatisticsFilters"),
    OrdersGrid: () => import("@/views/app/orders/grid/Index"),
    ChartDashboard: () => import("@/components/charts/ChartDashboard"),
    ContentCard: () => import("@/components/molecules/ContentCard"),
  },
  created() {
    this.headerAppSubscription = TheHeaderAppServices.command.subscribe(
      this.handleHeaderAppCommands
    );
  },
  destroyed() {
    this.headerAppSubscription.unsubscribe();
  },
  data() {
    return {
      isProMembership:
        this.$store.state.account.membershipType === 2 ||
        this.$store.state.account.membershipType === 3,
      ...StatisticsFilterType,
      DateFilterTypes,
      filters: {
        filterType: DateFilterTypes.Week,
        startDate: dates.toFormattedString(dates.getFirstDayOfWeek()),
        endDate: dates.toFormattedString(dates.getLastDayOfWeek()),
      },
      showSelectDate: false,
      drawer: false,
      loading: false,
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalPages: 0,
      },
      chartOptions: {
        labels: [],
        datasets: [],
      },
      headerAppSubscription: null,
      customFilters: {},
      isRangeDate: true,
      showChart: false,
      showOrdersGrid: false,
      showProductsGrid: false,
      gridOptions: [],
    };
  },
  computed: {
    filterByDayDate() {
      if (this.isRangeDate) {
        return `${this.dateFormat(this.filters.startDate).substr(
          0,
          5
        )} - ${this.dateFormat(this.filters.endDate).substr(0, 5)}`;
      }
      return `${this.dateFormat(this.filters.startDate).substr(0, 5)}`;
    },
  },
  methods: {
    clearDate() {
      this.showChart = false;
      this.filters = {};
    },
    dateFormat(date) {
      const format =
        this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
      return this.$moment(date).format(format);
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    updateFilters(filterType, date) {
      if (date) {
        this.filters = {
          filterType: this.filters.filterType,
          startDate: this.isRangeDate ? date[0].toString() : date,
          endDate: this.isRangeDate ? date[1].toString() : date,
        };
      }
      this.getDateFilters(this.filters, filterType, (filters) => {
        this.filters = filters;
        this.drawer = true;
      });
    },
    handleClose(done) {
      this.$confirm("Estás seguro que deseas cerrar los filtros?")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    fetch(customFilters = {}) {
      this.loading = true;
      this.customFilters = customFilters;
      this.load();
    },
    getTranslatedCategories(categories, filterType) {
      return categories.map((category) => {
        const translation = Translations[filterType];
        return translation[category] || category;
      });
    },
    buildOrderData({ orders: dictionary } = { orders: [] }, filters) {
      try {
        const categories = DateFilterCategories[filters.dateFilters.filterType];
        const series = [
          {
            name: "Dispositivos",
            data: [],
            isMoney: false,
            scalePosition: "left",
          },
          { name: "Monto", data: [], isMoney: true, scalePosition: "right" },
        ];

        for (const category of categories) {
          let devicesCount = 0;
          let amount = 0;

          const orders = categories.reduce((acc, curr) => {
            dictionary.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (orders) {
            this.showOrdersGrid = true;
            for (const order of orders) {
              this.gridOptions = [...this.gridOptions, order];
              const { orderDevices } = order;

              // Obtener la cuenta de dispositivos
              devicesCount += orderDevices.length;

              for (const orderDevice of orderDevices) {
                const { orderDeviceServices, orderDeviceProducts } =
                  orderDevice;

                // Sumar los servicios
                for (const orderDeviceService of orderDeviceServices) {
                  amount += orderDeviceService.price;
                }

                // Sumar los productos
                for (const orderDeviceProduct of orderDeviceProducts) {
                  amount += orderDeviceProduct.price;
                }
              }
            }
          }

          // Añadir los dispositivos y montos a la serie
          series[0].data.push(devicesCount);
          series[1].data.push(parseFloat(amount.toFixed(2)));
        }

        this.chartOptions.labels = this.getTranslatedCategories(
          categories,
          filters.dateFilters.filterType
        );
        this.chartOptions.datasets = this.getMultiAxisDatasets(
          "statistics",
          series
        );
      } catch (error) {
        console.log(error);
      }
    },
    buildEmployeeData({ employees: dictionary } = { orders: [] }, filters) {
      try {
        const categories = DateFilterCategories[filters.dateFilters.filterType];
        const series = [
          { name: "Ordenes", data: [], isMoney: false, scalePosition: "left" },
          { name: "Monto", data: [], isMoney: true, scalePosition: "right" },
        ];

        for (const category of categories) {
          let ordersCount = 0;
          let amount = 0;

          const orders = categories.reduce((acc, curr) => {
            dictionary.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (orders) {
            this.showOrdersGrid = true;
            // Obtener la cuenta de ordenes
            ordersCount += orders.length;

            // Sumar los montos
            for (const order of orders) {
              this.gridOptions = [...this.gridOptions, order];
              const { totalAmount } = order;
              amount += totalAmount;
            }
          }

          // Añadir las ordenes y los montos a la serie
          series[0].data.push(ordersCount);
          series[1].data.push(parseFloat(amount.toFixed(2)));
        }

        this.chartOptions.labels = this.getTranslatedCategories(
          categories,
          filters.dateFilters.filterType
        );
        this.chartOptions.datasets = this.getMultiAxisDatasets(
          "statistics",
          series
        );
      } catch (error) {
        console.log(error);
      }
    },
    buildCustomerData({ customers: dictionary } = { customers: [] }, filters) {
      try {
        const categories = DateFilterCategories[filters.dateFilters.filterType];
        const series = [
          { name: "Ordenes", data: [], isMoney: false, scalePosition: "left" },
          { name: "Monto", data: [], isMoney: true, scalePosition: "right" },
        ];

        for (const category of categories) {
          let ordersCount = 0;
          let amount = 0;

          const orders = categories.reduce((acc, curr) => {
            dictionary.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (orders) {
            this.showOrdersGrid = true;
            // Obtener la cuenta de ordenes
            ordersCount += orders.length;

            // Sumar los montos
            for (const order of orders) {
              this.gridOptions = [...this.gridOptions, order];
              const { totalAmount } = order;
              amount += totalAmount;
            }
          }

          // Añadir las ordenes y los montos a la serie
          series[0].data.push(ordersCount);
          series[1].data.push(parseFloat(amount.toFixed(2)));
        }

        this.chartOptions.labels = this.getTranslatedCategories(
          categories,
          filters.dateFilters.filterType
        );
        this.chartOptions.datasets = this.getMultiAxisDatasets(
          "statistics",
          series
        );
      } catch (error) {
        console.log(error);
      }
    },
    buildProductData({ products: dictionary } = { products: [] }, filters) {
      try {
        const isSold =
          filters.customFilters.product.type === this.ProductType.Sold;
        const isStock =
          filters.customFilters.product.type === this.ProductType.Stock;

        const categories = DateFilterCategories[filters.dateFilters.filterType];
        const series = [
          {
            name: "Productos",
            data: [],
            isMoney: false,
            scalePosition: "left",
          },
        ];

        if (isSold) {
          series.push({
            name: "Monto",
            data: [],
            isMoney: true,
            scalePosition: "right",
          });
        }

        if (isStock) {
          series.push({
            name: "Costo",
            data: [],
            isMoney: true,
            scalePosition: "right",
          });
        }

        for (const category of categories) {
          let productsCount = 0;
          let amount = 0;

          const products = categories.reduce((acc, curr) => {
            dictionary.map(({ key, value }) => {
              if (key == curr) {
                acc[curr] = value || [];
              }
            });
            return acc;
          }, {})[category];

          if (products) {
            this.showProductsGrid = true;
            for (const product of products) {
              this.gridOptions = [...this.gridOptions, product];
              // Obtener la cuenta de productos
              productsCount += product.quantity;

              if (isSold) {
                amount += product.price * product.quantity;
              }

              if (isStock) {
                amount += product.cost * product.quantity;
              }
            }
          }

          // Añadir las ordenes y los montos a la serie
          series[0].data.push(productsCount);
          series[1].data.push(parseFloat(amount.toFixed(2)));
        }

        this.chartOptions.labels = this.getTranslatedCategories(
          categories,
          filters.dateFilters.filterType
        );
        this.chartOptions.datasets = this.getMultiAxisDatasets(
          "statistics",
          series
        );
      } catch (error) {
        console.log(error);
      }
    },
    mapper(filters, dictionary = {}) {
      const mappers = {
        [this.EntityType.Order]: this.buildOrderData,
        [this.EntityType.Employee]: this.buildEmployeeData,
        [this.EntityType.Customer]: this.buildCustomerData,
        [this.EntityType.Product]: this.buildProductData,
      };

      const { entity } = filters.customFilters;
      const builder = mappers[entity];

      builder(dictionary, filters);
    },
    async load() {
      this.showChart = false;
      this.showOrdersGrid = false;
      this.showProductsGrid = false;
      this.gridOptions = [];
      try {
        const filters = {
          customFilters: this.customFilters,
          dateFilters: this.filters,
        };
        this.mapper(
          filters,
          await StatisticService.get({
            ...filters,
            businessInfoId: this.$store.state.business.id,
          })
        );
        this.drawer = false;
        this.showChart = true;
        this.loading = false;
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    handleHeaderAppCommands({ type }) {
      switch (type) {
        case TheHeaderAppServices.commands.onBusinessChange:
          if (this.activeTab === "statistics") {
            this.load();
          }
          break;
        default:
      }
    },
    handleShowFilters() {
      this.showSelectDate = true;
    },
    async download() {
      this.loading = true;
      try {
        const filters = {
          customFilters: this.customFilters,
          dateFilters: this.filters,
        };
        const url = await StatisticService.download({
          ...filters,
          businessInfoId: this.$store.state.business.id,
        });
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Finanzas+csv.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => this.removeFileFromS3(url), 10000);
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      } finally {
        this.loading = false;
      }
    },
    async removeFileFromS3(url) {
      const fileName = `import-export/${url.split("import-export/")[1]}`;
      await FileService.delete({ files: [fileName] });
    },
  },
};
</script>
