import HttpProxy from '@/services/HttpProxy';

export default class FinanceService {
  static async get(data) {
    const response = await HttpProxy
      .submit({ method: 'post', url: '/Finances', data });
    return response?.data ?? null;
  }

  static async download(businessInfoId, filters) {
    const response = await HttpProxy
      .submit({ method: 'post', url: `/Finances/DownloadFile`, data: { ...filters, businessInfoId } });
    return response?.data ?? null;
  }
}
