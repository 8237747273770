import { getLightColor } from '@/utils/colors';

export default {
    methods: {
        getSingleAxisDatasets(series, filled = false) {
            return series.map((serie) => ({
                label: serie.name,
                data: serie.data,
                borderColor: getLightColor('123456789'),
                backgroundColor: filled ? getLightColor('123456789') : 'rgba(0, 0, 0, 0)',
                customProps: {
                    isMoney: serie.isMoney,
                    scalePosition: serie.scalePosition || 'left',
                },
              }));
        },
        getMultiAxisDatasets(chart, series, filled = false) {
            return series.map((serie, index) => ({
                label: serie.name,
                data: serie.data,
                borderColor: getLightColor('123456789'),
                backgroundColor: filled ? getLightColor('123456789') : 'rgba(0, 0, 0, 0)',
                yAxisID: `${chart}-axis-${index + 1}`,
                ticks: {
                    min: serie.data.reduce((acc, curr) => acc > curr ? curr : acc, 0),
                    max: serie.data.reduce((acc, curr) => acc > curr ? acc : curr, 0),
                },
                customProps: {
                    isMoney: serie.isMoney,
                    scalePosition: serie.scalePosition || 'left',
                },
            }));
        },
    },
};
