import { DateFilterTypes } from '@/constants';
import dates from '@/utils/dates';

export default {
  methods: {
    getDateFilters(
      filters = {
        startDate: new Date(),
        endDate: new Date(),
        filterType: DateFilterTypes.Week,
      },
      filterType = DateFilterTypes.Week,
      callback = () => {},
    ) {
      let { startDate, endDate } = { ...filters };

      if (filterType === DateFilterTypes.Day) {
        startDate = dates.toFormattedString(startDate);
        endDate = dates.toFormattedString(endDate);
      }

      if (filterType === DateFilterTypes.Week) {
        startDate = dates.toFormattedString(dates.getFirstDayOfWeek());
        endDate = dates.toFormattedString(dates.getLastDayOfWeek());
      }

      if (filterType === DateFilterTypes.Month) {
        startDate = dates.toFormattedString(dates.getFirstDayOfYear());
        endDate = dates.toFormattedString(dates.getLastDayOfYear());
      }

      if (filterType === DateFilterTypes.Year) {
        startDate = dates.toFormattedString(dates.getSamiiFirstYear());
        endDate = dates.toFormattedString(dates.getSamiiLastYear());
      }

      filters = {
        filterType,
        startDate,
        endDate,
      };

      if (callback && typeof callback === 'function') {
        callback(filters);
      }

      return filters;
    },
  },
};
